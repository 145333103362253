/* Done */

.navSlider {
display: flex;
justify-content: space-around;
align-items: center;
width: 100%;
height: 87px;
background-color: white;
box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
z-index: 999;
}


nav ul {
display: flex;
align-items: center;
gap: 1.5vw;
}


nav .logo_div {
display: flex;
text-align: start;
align-items: center;
}

nav .logo_div img {
width: 81px;
height: 91px;
margin-top: -0.3em;
cursor: pointer;
}

nav h1 {
font-size: 1.7rem;
}

nav li a:hover {
color: rgb(115, 21, 162);
}

nav button {
padding: 17px;
border-radius: 3px;
cursor: pointer;
font-weight: bold;
text-align: center;
background-color: rgb(97, 13, 206);
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
transition: background-color 0.3s ease, transform 0.2s ease,
box-shadow 0.3s ease;
}

nav button a {
color: white;
font-weight: normal;
font-size: 0.9rem;
}

nav button:hover {
transform: scale(1.05);
}

nav button:active {
transform: scale(0.98);
}

.sticky {
position: fixed;
top: 0;
}

@media (max-width: 1024px) {
.navSlider {
flex-direction: row;
justify-content: space-around;
overflow: hidden;
background-color: white;
}

nav .nav_bar_ul {
display: none;
}

.navSlider.active .nav_bar_ul {
display: block;
flex-direction: column;
align-items: center;
position: absolute;
top: 140px;
right: 0;
line-height: 2.9em;
background-color: white;
width: 100%;
height: auto;
padding: 27px 0px 57px 0px;
box-shadow: 0 4px 6px;
z-index: 999;
}

.navSlider button {
margin-top: 1.4em;
}

.hamburger {
display: flex;
flex-direction: column;
cursor: pointer;
}

.hamburger span {
background-color: #000000;
height: 3px;
width: 25px;
margin: 5px 0;
}
}

@media (min-width: 1024px) and (max-width: 1516px) {

nav ul {
display: flex;
align-items: center;
}

nav ul li {
font-size: 0.9rem;
}

nav button {
padding: 15px;
}

nav h1 {
font-size: 1.6rem;
}

}
