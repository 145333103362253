/* Done */

header {
display: flex;
justify-content: center;
margin-top: 7.5em;
text-align: start;
gap: 4.3vw;
width: 100%;
}


header .headerdiv_ {
width: 427px;
height: 727px;
background-color: slateblue;
padding: 37px;
padding-top: 77px;
padding-bottom: 107px;
box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
color: #f5f5f5;
text-align: justify;
hyphens: auto;
}


header ul {
padding: 1.69em;
line-height: 3.1em;
margin-top: -1.9em;
}

header h1 {
margin-bottom: 0.7em;
font-size: 2rem;
color: silver;
}

header h2 {
text-align: start;
}

header li {
font-size: 1rem;
cursor: pointer;
}

header li a:hover {
transition: 0.5s;
color: rgb(150, 35, 207);
}



@media (max-width: 1024px) {

header {
flex-direction: column;
align-items: center;
text-align: center;
padding-bottom: 117px;
gap: 0em;
}


header .headerdiv_ {
width: 90%;
margin-bottom: 6.7vh;
height: auto;
hyphens: auto;
padding: 27px;
}

}

/*  */

@media (min-width: 1025px) and (max-width: 1500px) {

header {
display: flex;
justify-content: center;
width: 100%;
margin: auto;
margin-top: 7.7em;
gap: 3.3vw;
}


header .headerdiv_ {
width: 397px;
padding: 33px;
}


header ul li a {
font-size: 0.9rem;
}

header h1 {
font-size: 1.6rem;
color: silver;
}

}
