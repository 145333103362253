.message_icon {
position: fixed;
width: 127px;
bottom: -7px;
right: -7px;
cursor: pointer;
z-index: 1010;
}

.chatApp_form {
position: fixed;
bottom: 20px;
right: 20px;
width: 333px;
height: 487px;
max-width: 90%;
padding: 27px;
background-color: #f0f0f0;
border-radius: 3px;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
z-index: 1000;
}

.chatApp_form h1 {
text-align: center;
font-size: 18px;
color: #333;
font-size: 1.5rem;
line-height: 1.6em;
margin-bottom: 11px;
}

.chatApp_form img {
display: block;
margin: 0 auto 10px auto;
width: 50px;
height: 50px;
}

.chatApp_form input[type="text"],
.chatApp_form input[type="email"],
.chatApp_form textarea {
width: 100%;
padding: 10px;
margin: 6px 0;
border: 1px solid #ddd;
border-radius: 5px;
resize: none;
font-size: 14px;
color: #333;
background-color: #fff;
}

.chatApp_form textarea {
height: 80px;
}

.chatApp_form input::placeholder,
.chatApp_form textarea::placeholder {
color: #999;
}

.chatApp_form button {
width: 100%;
padding: 17px;
background-color: #ca40f1;
color: white;
font-size: 14px;
font-weight: bold;
border: none;
border-radius: 3px;
cursor: pointer;
margin-top: 10px;
transition: background-color 0.3s ease;
}

.chatApp_form button:hover {
background-color: #5229b1;
}

.chatApp_form input:focus,
.chatApp_form textarea:focus {
outline: none;
border: 3px solid #38278d;
}

.success_message {
margin-top: 1.1em;
}

@media (max-width: 400px) {
.chatApp_form {
width: 90%;
bottom: 10px;
right: 13px;
padding: 27px;
}

.chatApp_form h1 {
font-size: 1.5em;
}

.chatApp_form input[type="text"],
.chatApp_form input[type="email"],
.chatApp_form textarea {
font-size: 13px;
}

.chatApp_form button {
font-size: 13px;
}
}
