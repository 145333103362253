/* Done */

.form_contact {
display: flex;
justify-content: center;
padding: 2.7em;
gap: 5.7vw;
text-align: start;
margin: 4.7em auto 0;
}

.form_contact input,
.form_contact textarea {
width: 357px;
padding: 0.9em;
margin-top: 0.7em;
outline: none;
border: 1px solid rgb(169, 163, 163);
border-radius: 4px;
font-size: 0.9rem;
}

.form_contact textarea {
padding: 1.5em;
}

.form_contact button {
padding: 1.2em;
width: 100%;
margin: 1.5em 0em 0.7em 0em;
color: white;
border-radius: 3px;
border: none;
outline: none;
cursor: pointer;
font-size: 1rem;
background-color: rgb(58, 46, 142);
transition: background 0.3s ease, transform 0.3s ease;
}

.form_contact button:hover {
background-color: rgb(58, 25, 123);
transform: translateY(-5px);
}

.form_contact h1:first-child {
color: #10204f;
font-size: 2.5rem;
margin-bottom: 0.7em;
line-height: 1.4em;
}

.form_contact img {
width: 475px;
cursor: pointer;
}

.hideInput_Contact {
display: none;
}

.success_message {
text-align: center;
}

@media (max-width: 1024px) {
.form_contact {
flex-direction: column;
width: 100vw;
padding: 1.5em;
margin: 3.7em auto 0;
}

.form_contact img {
width: 100%;
margin: auto;
margin-left: 0;
}

.form_contact input {
width: 100%;
}

.form_contact textarea {
width: 100%;
}

.form_contact button {
width: 100%;
}

.form_contact h1:first-child {
font-size: 2.1rem;
text-align: center;
}
}

/*  */

@media (min-width: 1025px) and (max-width: 1500px) {
.form_contact {
width: 100%;
gap: 4.7vw;
}

}
