/* Done */

.container {
display: flex;
justify-content: flex-start;
align-items: flex-start;
gap: 2.5vw;
max-width: 1100px;
margin: 0 auto;
padding: 40px 20px;
flex-wrap: wrap;
}

.container img {
width: 100%;
max-width: 500px;
height: auto;
border-radius: 8px;
}

.form {
background-color: #333;
box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
padding: 30px 40px;
width: 100%;
max-width: 500px;
border-radius: 5px;
display: flex;
flex-direction: column;
gap: 1.2em;
padding-bottom: 57px;
line-height: 1.6em;
border: 1px solid #e0e0e0;
}

.form h1 {
margin: 0.5em 0;
font-weight: 600;
line-height: 1.5em;
font-size: 2.7rem;
text-align: center;
color: white;
}

.form h4 {
font-weight: 500;
color: white;
margin: 0.4em 0;
font-size: 1.1rem;
text-align: left;
}

.form input,
.form select {
padding: 12px;
width: 100%;
background-color: #fff;
border: 1px solid #ccc;
color: #444;
border-radius: 3px;
outline: none;
font-size: 1rem;
transition: all 0.2s ease;
}

.form input:focus,
.form select:focus {
border-color: #5d9cec;
box-shadow: 0 0 6px rgba(93, 156, 236, 0.3);
}

.resultContainer {
text-align: center;
color: #333;
font-size: 1.1rem;
margin-top: 2.3em;
}

.resultContainer h2 {
color: white;
line-height: 1.7em;
}

.resultContainer {
font-size: 1.1rem;
text-align: center;
color: #333;
}

@media (max-width: 768px) {
.container {
flex-direction: column;
}

.form {
width: 100%;
}

.form h1 {
    font-size: 2.3rem;
}

}
