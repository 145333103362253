/* Done */

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider_div_Dash {
display: flex;
align-items: center;
width: 753px;
margin: auto;
margin-top: 3.3em;
cursor: pointer;
}

.slider_div_Dash div img {
width: 100%;
height: 100%;
}


@media (max-width: 1024px) {
.flex_col_home_Dash {
width: 80%;
margin: auto;
}

.flex_col_home_Dash img {
width: 100%;
}

.slider_div_Dash {
width: 100%;
height: auto;
}

}
