.App {
text-align: center;
}

.App-logo {
height: 40vmin;
pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
.App-logo {
animation: App-logo-spin infinite 20s linear;
}
}

.App-header {
background-color: #282c34;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}

.App-link {
color: #61dafb;
}

@keyframes App-logo-spin {
from {
transform: rotate(0deg);
}
to {
transform: rotate(360deg);
}
}

* {
padding: 0;
margin: 0;
box-sizing: border-box;
font-family: Verdana, Geneva, Tahoma, sans-serif;
}

li {
list-style: none;
cursor: pointer;
}

a {
text-decoration: none;
color: black;
}

p {
font-size: 1.1rem;
line-height: 1.9em;
text-align: justify;
hyphens: auto;
}

h1,
h2,
h3,
h4,
h5,
p {
font-weight: normal;
text-align: justify;
hyphens: auto;
}

p {
line-height: 2.2em;
}

button {
border: none;
outline: none;
border-radius: 3px;
cursor: pointer;
}
